<template id="tmplProductDetails">
  <section id="secProductDetails" class="container mt-3">
    <b-row id="rowHeaders">
      <h4 id="hdrPrimary" class="text-primary">Product Details </h4>
    </b-row>
    <b-row>
      <b-button id="btnShowAddProduct" variant="link" @click="showProductSlideIn()" size="lg" :disabled="consignees.length == 0">
        <i id="iAddProduct" class="fa fa-plus fa-md mr-2"></i>Add Product
      </b-button>
    </b-row>
    <b-row id="rowProductsTable">
      <b-table id="tblProducts"
        ref="productsTable"
        :fields="tableDefaultFields"    
        :items.sync="selectedProducts"
        :show-empty="true"
        :empty-text="table.emptyText"
        :responsive="true"
        :filter="null"
        :small="true"
      >
        <template id="tmplDeliverySite" v-slot:cell(deliverySite)="row">
          <div id="divDeliveryAddress"
            class="text-wrap text-break"
            style="max-width:150px;"
          >{{ getDeliveryAddress(row.value, 'N/A') }}</div>
        </template>
        <template id="tmplShipper" v-slot:cell(shipper)="row">{{ row.value ? row.value.name : 'N/A'}}</template>
        <template id="tmplSupplier" v-slot:cell(supplier)="row" :visible="order.division!='SPG'">{{ row.value ? row.value.name : 'N/A'}}</template>
        <template id="tmplAccoutOf" v-slot:cell(accountOf)="row" :visible="order.division!='SPG'">{{ row.value ? row.value.name : 'N/A'}}</template>
        <template id="tmplProduct" v-slot:cell(product)="row">{{ row.value ? row.value.name : 'N/A'}}</template>        
        <!-- <template v-slot:cell(amount)="row">{{ row.value + ' ' + row.item.amountUnitOfMeasure }}</template> -->
        <template id="tmplAmount" v-slot:cell(amount)="row">{{ buildQuantityString(row) }}</template>
        <template id="tempActions" v-slot:cell(actions)="row">
          <i id="iEditProduct" class="fa fa-edit fa-lg text-info icon" @click="editProduct(row.index)"></i>
          <i id="iDeleteProduct" class="fa fa-trash ml-2 fa-lg text-danger icon" @click="deleteProduct(row.index)"></i>
        </template>
        <!-- <template v-slot:cell(accountOf)="scope" :visible="order.division!='SPG'">
          <h5 class="text-center">{{ scope.emptyText }}</h5>
        </template> -->
      </b-table>
    </b-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { required, between } from "vuelidate/lib/validators";
import { handleRequestError } from "@/shared/utils/response-error-handler";
//import _ from "lodash";
import { divisions, minCapacity, maxCapacity } from "@/shared/constants"
import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";
import { Promise } from "q";
import ProductSlideIn from "@/components/new-order-template/ProductSlideIn";
import mixin from "@/shared/mixin";
  export default {
    name: "ProductDetails.vue",
    components: {
      vSelect
    },
    data() {
      return {
        minCapacity,
        maxCapacity,
        showModal: false,
        editIndex: null,
        isAdvancedView: false,
        division: null,
			spgTable: {
				fields: [
					"deliverySite",
					"shipper",
					"product",
					"amount",
					"actions"
				],
				emptyText: "You have no products.  Please click the 'Add Product' button above to add some."
			},
			table: {
				fields: [
					"deliverySite",
					"shipper",
					"supplier",
					"accountOf",
					"product",
					"amount"
				],
				emptyText: "You have no products.  Please click the 'Add Product' button above to add some."
			}
      };
    },
    created() {
      this.$root.$on("refreshProductsTable", () => {
        if (this.$refs.productsTable) this.$refs.productsTable.refresh();
      });
    },
    mounted() {
      //can check state objects in here.
      if (this.tenderForConsigneeId) {
        this.showProductSlideIn();
      }
    },
    mixins: [mixin],
    methods: {
      toggleAdvancedView() {
        this.$v.$reset();
        this.$store.dispatch("newOrderTemplate/setSelectedShipper", "");
        this.$store.dispatch("newOrderTemplate/setSelectedSupplier", "");
        this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");

        this.isAdvancedView = !this.isAdvancedView;
      },
      showProductModal() {
        this.isAdvancedView = false;
        this.showModal = true;
      },
      addProduct() {
        try {
          var objProduct = {
            product: {
              shipper: this.selectedShipper,
              supplier: this.selectedSupplier,
              accountOf: this.selectedAccountOf,
              product: this.selectedCommodity,             
              amount: this.selectedQuantity,
              amountUnitOfMeasure: this.selectedAmountUnitOfMeasure
            },
            productIndex: this.editIndex != null ? this.editIndex : null
          };

          this.showModal = false;
          this.$store.dispatch("newOrderTemplate/addProduct", objProduct);
          this.$store.dispatch("newOrderTemplate/clearProductDetails");               
          this.editIndex = null;
          this.$refs.productsTable.refresh();
        } catch (error) {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }
      },
      async editProduct(index) {
        this.$root.$emit("isEditLoading", true);
				this.$store.dispatch("newOrderTemplate/setIsEditMode", true);
        try {
          var product = this.selectedProducts[index];

          this.$store.dispatch(
            "newOrderTemplate/setSelectedConsignee",
            product.deliverySite
          );

          if (product.shipper && product.shipper.id != "CALLDIS") {
            this.$store.dispatch(
              "newOrderTemplate/setSelectedShipper",
              product.shipper
            );
          }

          if (product.supplier != null && product.supplier.id != "CALLDIS") {
            this.$store.dispatch(
              "newOrderTemplate/setSelectedSupplier",
              product.supplier
            );
          }

          if (product.accountOf != null ? product.accountOf.id : "") {
            this.$store.dispatch(
              "newOrderTemplate/setSelectedAccountOf",
              product.accountOf
            );
          }

          if (product.product != null ? product.product.id : "") {
            this.$store.dispatch(
              "newOrderTemplate/setSelectedCommodity",
              product.product
            );
          }

          this.$store.dispatch(
            "newOrderTemplate/setSelectedQuantity",
            product.amount
          );

    this.$store.dispatch(
            "newOrderTemplate/setSelectedQuantity",
            product.amount
          );

          //Get Data for dropdowns
          var shippers, suppliers, accountOfs, commodities;
          shippers = this.$store.dispatch("newOrderTemplate/getShippers", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id
          });

          if (this.selectedShipper) {
            suppliers = this.$store.dispatch("newOrderTemplate/getSuppliers", {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id
            });
          }

          if (this.selectedSupplier) {
            accountOfs = this.$store.dispatch("newOrderTemplate/getAccountOfs", {
              jwtToken: this.$store.state.user.jwtToken,
              billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id,
              supplier: this.selectedSupplier.id
            });
          }

          commodities = this.$store.dispatch("newOrderTemplate/getCommodities", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id
          });

          await Promise.all([shippers, suppliers, accountOfs, commodities]);
          var mode;
          if (this.createdDate) {
            mode = "Edit";
          }

          
          const panelInstance = this.$showPanel({
            component: ProductSlideIn,
            props: { editIndex: index, Mode: mode },
						openOn: 'right'
          });
         
          this.$root.$emit("isEditLoading", false);
        } catch (error) {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");

          this.$root.$emit("isEditLoading", false);
        }
      },
      deleteProduct(index) {
        try {
          this.$store.dispatch("newOrderTemplate/deleteProduct", index);
        } catch (error) {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }
      },
      onModalClosed() {
        this.$store.dispatch("newOrderTemplate/clearProductDetails");
        this.$v.$reset();
        this.editIndex = null;
        this.showModal = false;
      },
      async onShipperChange(value) {
        try {
          this.$store.dispatch("newOrderTemplate/setSelectedSupplier", "");
          this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
          this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
          this.$store.dispatch("newOrderTemplate/setSelectedShipper", value);

          if (this.selectedShipper != "") {
            
            var suppliers = await this.$store.dispatch(
              "newOrderTemplate/getSuppliers",
              {
                jwtToken: this.$store.state.user.jwtToken,
                billTo: this.$store.state.newOrderTemplate.selectedConsignee
                  .billTo,
                consignee: this.$store.state.newOrderTemplate.selectedConsignee
                  .id,
                shipper: this.selectedShipper.id
              }
            );
         
          }
        } catch (error) {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }
      },
      async onSupplierChange(value) {
        try {
          this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
          this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
          this.$store.dispatch("newOrderTemplate/setSelectedSupplier", value);

          if (this.selectedSupplier) {
            
            var accountOfs = await this.$store.dispatch(
              "newOrderTemplate/getAccountOfs",
              {
                jwtToken: this.$store.state.user.jwtToken,
                billTo: this.$store.state.newOrderTemplate.selectedConsignee
                  .billTo,
                consignee: this.selectedConsignee.id,
                shipper: this.selectedShipper.id,
                supplier: this.selectedSupplier.id
              }
            );
          }
        } catch (error) {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }
      },
      chkState(val) {
        const field = this.$v[val];
        return !field.$dirty || !field.$invalid;
      },
      onAccountOfChange(value) {
        this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", value);
      },
      onCommodityChange(value) {
        this.$store.dispatch("newOrderTemplate/setSelectedCommodity", value);
      },
      onQuantityChange(value) {
        this.$store.dispatch("newOrderTemplate/setSelectedQuantity", value);
      },
      showProductSlideIn() {
        const panelInstance = this.$showPanel({
          component: ProductSlideIn,
					openOn: 'right'
        });

        
        panelInstance.promise.then(result => {
          this.$store.dispatch("newOrderTemplate/setSelectedConsignee", "");
          this.$store.dispatch("newOrderTemplate/clearProductDetails");
        });
          
      },   
      buildQuantityString(row) {      
        if (row.item.callForQuantity) {
          return "Call For Quantity";
        }
        return row.value + " " + row.item.amountUnitOfMeasure;
      }
    },  
    computed: {
      ...mapState("newOrderTemplate", [
        "commodities",
        "consignees",
        "shippers",
        "suppliers",
        "accountOfs",
        "selectedConsignee",
        "selectedProducts",
        "selectedShipper",
        "selectedSupplier",
        "selectedAccountOf",
        "selectedCommodity",
        "selectedSplashCommodity",
        "selectedQuantity",
        "selectedAmountUnitOfMeasure"
      ]),
      tenderForConsigneeId() {
        return this.$route.query.consigneeId;
      },
  
      tableDefaultFields() {
        const mapEmitter = new Map([
          [
            divisions.SPG, () => this.spgTable.fields
          ],
          [
            divisions.FDG, () => {

              if (this.selectedProducts.some(e => e.isAdvancedView)) {
                return this.table.fields;

              } else {
                return _.difference(this.table.fields,
                  [
                    "supplier",
                    "accountOf"
                  ])

              }
            }
          ]
        ])

        if (this.selectedProducts.length) {
          let product = this.selectedProducts[0];
          return mapEmitter.get(product.deliverySite.division == divisions.FDG ? divisions.FDG : divisions.SPG)()
        } else {
          //just a default value to not throw errors
          return mapEmitter.get(divisions.FDG)()
        }
      },
    },
    validations() {
      if (!this.isAdvancedView) {
        return {
          selectedQuantity: {
            required,
            between: between(this.minCapacity.FDG, this.maxCapacity.FDG)
          },
          selectedCommodity: {
            required
          }
        };
      } else {
        return {
          selectedQuantity: {
            required,
            between: between(this.minCapacity.SPG, this.maxCapacity.SPG)
          },
          selectedCommodity: {
            required
          },
          selectedShipper: {
            required
          },
          selectedSupplier: {
            required
          },
          selectedAccountOf: {
            required
          }
        };
      }
    }
  }
  
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.panel-right {
  right: 0;
  transform: translateX(100%);
}
</style>

