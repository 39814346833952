<template id="tmplSpecialInstructions">
  <section id="secContainerOne" class="container mt-3">
    <b-row id="rowSpecialInstructionsTitle">
      <h4 id="hdrSpecialInstructionsTitle" class="text-primary">Special Instructions & Notes</h4>
    </b-row>
    <b-row id="rowPOSalesOrderNo">
      <b-col id="colPOSalesNumber" lg="4" md="4" sm="12" class="pd-10">
        <b-form-group id="grpPOSalesNumber" label="PO/Sales Order #" label-for="txtPoSalesNumber">
          <b-form-input id="txtPoSalesNumber" v-model="poNumber"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row id="rowAdditionalNotes">
      <b-col id="colAdditionalNotes" lg="8" md="8" sm="12">
        <b-form-group id="grpAdditonalNotes" label="Additional Notes/Remarks" label-for="txtNotes">
          <b-form-textarea id="txtNotes" v-model="notes" rows="4" max-rows="6"/>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  export default {
  name: "SpecialInstructions.vue",
  computed: {
    poNumber: {
      get() {
        return this.$store.state.newOrderTemplate.poNumber;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setPoNumber", value);
      }
    },
    notes: {
      get() {
        return this.$store.state.newOrderTemplate.notes;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setNotes", value);
      }
    }
  }
};
</script>
