<template id="tmplNewOrderProductSlideIn">
  <section id="secContainerOne" class="container grey-background slide">
    <div id="divParent" class="container vld-parent">
      <loading
        id="ldNewOrderProductSlideIn"
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>

      <b-row
        id="rowAddProductTitleRow"
        style="display: flex; justify-content: space-between"
      >
        <h1 id="hdrAddProductsTitle" class="text-primary mb-1 ml-4 mb-3">
          Add Products
        </h1>
        <b-button id="btnCloseSlideout" variant="link" @click="closeWindow()">
          <i class="fa fa-lg fa-window-close" aria-hidden="true"></i>
        </b-button>
      </b-row>

      <b-card id="crdCardShadowBox" class="card-box-shadow">
        <b-row id="rowOneProductSlideIn">
          <b-col id="colDeliverySite" class="pd-10" cols="10" md="7">
            <label id="lblDeliverySite">Delivery Site</label>
            <v-select
              label="name"
              id="ddlDeliverySite"
              @input="onDeliverySiteChanged"
              :value="$store.state.newOrderTemplate.selectedConsignee"
              :disabled="consignees.length == 0 || Mode == 'Edit'"
              :options="filteredConsigneesWithKey"
              placeholder="Select Delivery Site"
              :get-option-label="getDeliveryLabel"
            >
              <template
                id="tmplDeliverySiteOption"
                slot="option"
                slot-scope="option"
                class="mt-4"
              >
								<div class="row mb-2">
									<div class="col-sm">{{ option.name }}</div>
									<div class="col-sm">{{ option.billTo }}</div>		
								</div>
                <span
                  id="spnDelvieryStieOptionCityStateText"
                  class="city-state-text"
                >
									<div class="row">
										<div class="col-sm mb-2">
											{{ option.address }}
										</div>
										<div class="col-sm mb-2" v-if="option.address2 && option.address2.length > 0">
											{{ option.address2 }}
										</div>
										<div class="col-sm">
											{{ option.city }} {{ option.state }} {{ option.zipCode }}
										</div>
									</div>
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col id="colDeliveryPopup" cols="1">
            <i
              id="iDeliverySitePopup"
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover="deliverySitePopoverText"
              title="Delivery Site"
            ></i>
          </b-col>
          <b-col
            id="colAdvancedView"
            cols="12"
            md="4"
            class="text-right"
            v-if="!isHideOnEnhancedBasicMode && isDeliverySiteSelected"
          >
            <b-button
              id="btnIsAdvancedView"
              v-if="isAvailableAdvancedView"
              variant="outline-info"
              size="sm"
              @click="toggleAdvancedView()"
              class="mt-4"
            >
              {{
                isAdvancedView ? "Show Basic Options" : "Show Advanced Options"
              }}
            </b-button>
          </b-col>
        </b-row>

        <b-row
          id="rowShowAfterDivisionSelection"
          v-show="isDeliverySiteSelected"
        >
          <b-col
            id="colShipper"
            v-if="isAdvancedView"
            class="pd-10"
            lg="4"
            md="4"
            sm="6"
          >
            <label id="lblShipper">Shipper</label>
            <v-select
              label="name"
              id="ddlShipper"
              @input="onShipperChange"
              :value="selectedShipper"
              :disabled="shippers.length == 0"
              :options="shippers"
              placeholder="Select Shipper"
            >
            </v-select>
          </b-col>

          <b-col
            id="colSupplier"
            v-if="isAdvancedView && !isHideOnEnhancedBasicMode"
            class="pd-10"
            lg="4"
            md="4"
          >
            <label id="lblSupplier">Supplier</label>
            <v-select
              id="ddlSupplier"
              label="name"
              @input="onSupplierChange"
              :disabled="selectedShipper == '' || suppliers.length == 0"
              :value="selectedSupplier"
              :options="suppliers"
              placeholder="Select Supplier"
            >
            </v-select>
          </b-col>

          <b-col
            id="colAccountOf"
            v-if="isAdvancedView && !isHideOnEnhancedBasicMode"
            class="pd-10"
            lg="4"
            md="4"
          >
            <label id="lblAccountOf">Account Of</label>
            <v-select
              id="ddlAccountOf"
              label="name"
              :disabled="selectedSupplier == '' || accountOfs.length == 0"
              :options="accountOfs"
              :value="selectedAccountOf"
              @input="onAccountOfChange"
              placeholder="Select Account Of"
            />
          </b-col>
        </b-row>

        <b-row id="rowTwoProductSlideIn" v-show="isDeliverySiteSelected">
          <b-col id="colCommodity" class="pd-10" lg="8" md="8" sm="6">
            <label id="lblCommodity">Commodity</label>
            <v-select
              id="ddlCommodity"
              label="name"
              @input="onCommodityChange"
              :disabled="isDisabledCommodity"
              :options="commodities"
              :value="selectedCommodity"
              placeholder="Select Commodity"
            />
          </b-col>
          <b-col id="colQuantity" class="pd-10" lg="4" md="4" sm="6">
            <b-form-group id="frmGrpTxtQuantity" label="Quantity">
              <b-input-group id="inptGrpTxtQuantity">
                <b-form-input
                  id="txtQuantity"
                  type="number"
                  :value="selectedQuantity"
                  @change="onQuantityChange"
                />
                <template id="tmplAmmountUnitOfMeasure" v-slot:append>
                  <b-dropdown
                    :text="
                      (amountUnitOfMeasure != ''
                        ? amountUnitOfMeasure
                        : selectedAmountUnitOfMeasure) == ''
                        ? 'GAL'
                        : amountUnitOfMeasure != ''
                        ? amountUnitOfMeasure
                        : selectedAmountUnitOfMeasure
                    "
                    variant="secondary"
                    id="ddlAmountUnitOfMeasure"
                    @change="onAmountUnitOfMeasureChange(amountUnitOfMeasure)"
                  >
                    <b-dropdown-item
                      id="drpdnItemGal"
                      @click="amountUnitOfMeasure = 'GAL'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'GAL',
                      }"
                    >
                      GAL
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="drpdnItemLTR"
                      @click="amountUnitOfMeasure = 'LTR'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'LTR',
                      }"
                    >
                      LTR
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="drpdnItemPCS"
                      @click="amountUnitOfMeasure = 'PCS'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'PCS',
                      }"
                    >
                      PCS
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="drpdnItemTON"
                      @click="amountUnitOfMeasure = 'TON'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'TON',
                      }"
                    >
                      TON
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="drpdnItemLBS"
                      @click="amountUnitOfMeasure = 'LBS'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'LBS',
                      }"
                    >
                      LBS
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="drpdnItemKGS"
                      @click="amountUnitOfMeasure = 'KGS'"
                      :class="{
                        active:
                          (amountUnitOfMeasure != ''
                            ? amountUnitOfMeasure
                            : selectedAmountUnitOfMeasure) == 'KGS',
                      }"
                    >
                      KGS
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col id="colExtra"></b-col>
        </b-row>
        <b-row id="rowBetween" align-h="between" class="mt-3">
          <b-col id="colBetween" cols="5"> </b-col>
          <b-col id="colNavButtons" cols="5" style="text-align: right">
            <div id="divNavigationalProcessButtons">
              <button
                id="btnCloseWindow"
                type="button"
                class="btn btn-outline-danger mr-2"
                @click="closeWindow()"
              >
                Close
              </button>

              <span
                id="spnAddProductWithToolTip"
                class="d-inline-block"
                tabindex="0"
              >
                <b-button
                  id="btnAddProductTooltip"
                  class="btn btn-success"
                  @click="addProduct()"
                >
                  {{ editIndex == null ? "Add Product" : "Edit Product" }}
                </b-button>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <vue-snotify id="ntfSpaceforNotifications"></vue-snotify>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { handleRequestError } from "@/shared/utils/response-error-handler";

  import {
    divisions, minCapacity, maxCapacity } from "@/shared/constants";

import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";

import { isNullOrUndefined } from "util";
import mixin from "@/shared/mixin";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ProductSlideIn.vue",
  props: ["Mode", "editIndex"],
  components: {
    vSelect,
    Loading,
  },

  mixins: [mixin],

  data() {
    return {
      isLoading: false,
      isAdvancedView: false,
      isAvailableAdvancedView: true,
      amountUnitOfMeasure: "GAL",
      isDisabledCommodity: true,
      isDeliverySiteSelected: false,
      isHideOnEnhancedBasicMode: false,
      minCapacity,
      maxCapacity
    };
  },

  async mounted() {
    const aum =
      this.selectedAmountUnitOfMeasure != ""
        ? this.selectedAmountUnitOfMeasure
        : this.amountUnitOfMeasure;
    this.amountUnitOfMeasure = aum != "" ? aum : "GAL";

    if (!this.getIsEditMode) {
      if (this.selectedShipper) {
        this.isAdvancedView = true;
      }
    }
    if (this.selectedConsignee) {
      this.isDeliverySiteSelected = true;
      await this.setEnhancedBasicMode();
    }
  },

  methods: {
    toggleAdvancedView() {
      this.$store.dispatch("newOrderTemplate/setSelectedShipper", "");
      this.$store.dispatch("newOrderTemplate/setSelectedSupplier", "");
      this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
      this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
      this.isAdvancedView = !this.isAdvancedView;
    },

    async onDeliverySiteChanged(value) {
      try {
				if(value.consigneeId) value.id = value.consigneeId;
        this.$store.dispatch("newOrderTemplate/setSelectedConsignee", value);
        this.$store.dispatch("newOrderTemplate/clearProductDetails");

        if (value) {
          this.isDeliverySiteSelected = true;
          await this.setEnhancedBasicMode();
          await this.$store.dispatch("newOrderTemplate/getCommodities", {
            isAdvancedView: this.isAdvancedView,
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: value.id,
          });

          await this.$store.dispatch("newOrderTemplate/getShippers", {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: value.id,
          });

          if (!this.isAdvancedView) {
            this.enableSelectionCommodity();
          }
        } else {
          this.isAvailableAdvancedView = true;
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    addProduct() {
      if (
        this.isOrderAmountOverCapacity(this.selectedQuantity, this.editIndex)
      ) {
        let maxAvailableCapacity = this.maxCapacity.FDG;
        if (this.division === divisions.SPG) {
          maxAvailableCapacity = this.maxCapacity.SPG;
        }
        this.$snotify.error(
          `Product not added! Total quantity of order exceeds ${maxAvailableCapacity} ${this.amountUnitOfMeasure}.`,
          "Error",
        );
        return;
      }

      if (!this.selectedConsignee) {
        this.$snotify.error("You must select a delivery site.", "Error");
        return;
      }
      if (this.amountUnitOfMeasure == "") {
        if (this.selectedAmountUnitOfMeasure == "") {
          this.amountUnitOfMeasure == "GAL";
        }
      }


      //  if (!this.selectedconsignee.address1) {
      //   this.selectedconsignee.address1 =
      //     this.selectedconsignee.address;
      //  }
      
      var objProduct = {
        product: {
          deliverySite: this.selectedConsignee,
          shipper: this.selectedShipper,
          supplier: this.selectedSupplier,
          accountOf: this.selectedAccountOf,
          product: this.selectedCommodity,
          amount: this.selectedQuantity,
          amountUnitOfMeasure:
            this.amountUnitOfMeasure == undefined
              ? this.selectedAmountUnitOfMeasure
              : this.amountUnitOfMeasure,
          isAdvancedView: this.isAdvancedView,
        },
        productIndex: this.editIndex != null ? this.editIndex : null,
      };

      this.$store.dispatch("newOrderTemplate/addProduct", objProduct);
      console.log("objProduct", objProduct);
      this.$store.dispatch("newOrderTemplate/clearProductDetails");

      if (this.editIndex != null) this.$emit("closePanel", {});
      else
        this.$snotify.success("Product added successfully.", "Success", {
          timeout: 1000,
          showProgressBar: false,
        });

      this.$root.$emit("toggleEditIndex", null);
      this.$root.$emit("refreshProductsTable", null);
    },

    deleteProduct(index) {
      this.$store.dispatch("newOrderTemplate/deleteProduct", index);
    },

    async onShipperChange(value) {
      try {
        this.$store.dispatch("newOrderTemplate/setSelectedSupplier", "");
        this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTemplate/setSelectedShipper", value);

        if (this.selectedShipper) {
          var suppliers = await this.$store.dispatch(
            "newOrderTemplate/getSuppliers",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo:
                this.$store.state.newOrderTemplate.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id,
            }
          );
        }
        this.enableSelectionCommodity();
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onSupplierChange(value) {
      try {
        this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", "");
        this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
        this.$store.dispatch("newOrderTemplate/setSelectedSupplier", value);

        if (this.selectedSupplier) {
          var accountOfs = await this.$store.dispatch(
            "newOrderTemplate/getAccountOfs",
            {
              jwtToken: this.$store.state.user.jwtToken,
              billTo:
                this.$store.state.newOrderTemplate.selectedConsignee.billTo,
              consignee: this.selectedConsignee.id,
              shipper: this.selectedShipper.id,
              supplier: this.selectedSupplier.id,
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },

    async onAccountOfChange(value) {
      this.$store.dispatch("newOrderTemplate/clearCommodities");
      this.$store.dispatch("newOrderTemplate/setSelectedCommodity", "");
      this.$store.dispatch("newOrderTemplate/setSelectedAccountOf", value);

      await this.$store.dispatch("newOrderTemplate/getCommodities", {
        isAdvancedView: this.isAdvancedView,
        jwtToken: this.$store.state.user.jwtToken,
        billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
        consignee: this.selectedConsignee.id,
        shipper: this.selectedShipper.id,
        supplier: this.selectedSupplier.id,
        accountOf: this.selectedAccountOf.id,
      });
      this.enableSelectionCommodity();
    },

    onCommodityChange(value) {
      this.$store.dispatch("newOrderTemplate/setSelectedCommodity", value);
    },

    onQuantityChange(value) {
      this.$store.dispatch("newOrderTemplate/setSelectedQuantity", value);
    },

    chkState(val) {
      const field = this.$v[val];
      return !field.$dirty || !field.$invalid;
    },

    closeWindow() {
      this.$emit("closePanel", {});
    },

    isOrderAmountOverCapacity(amount, editIndex) {
      var totalAmount = 0;

      for (var i = 0; i < this.selectedProducts.length; i++) {
        if (editIndex === i) {
          totalAmount += parseInt(amount);
        } else {
          totalAmount += parseInt(this.selectedProducts[i].amount);
        }
      }

      if (isNullOrUndefined(editIndex)) {
        totalAmount = parseInt(totalAmount) + parseInt(amount);
      }

      switch (this.currentBehaviorRelyingOnData) {
        case divisions.SPG: {
          if (totalAmount > this.maxCapacity.SPG) {
            return true;
          }
          break;
        }
        case divisions.FDG: {
          if (totalAmount > this.maxCapacity.FDG) {
            return true;
          }
          break;
        }
      }
      return false;
    },

    getDeliveryLabel(option) {
      //console.log("option", option);
      return this.getDeliveryAddress(option, "N/A");
    },

    setEnhancedBasicMode() {
      switch (this.getDivisionForTemplate) {
        case divisions.SPG:
          this.isAdvancedView = true;
          this.isHideOnEnhancedBasicMode = true;
          break;
      }
    },

    enableSelectionCommodity() {
      switch (this.getDivisionForTemplate) {
        case divisions.SPG:
          this.isDisabledCommodity = !(
            (this.isAdvancedView && this.selectedShipper != null) ||
            this.commodities.length == 0
          );
          break;

        default:
        case divisions.FDG:
          this.isDisabledCommodity =
            (this.isAdvancedView && this.selectedAccountOf == "") ||
            this.commodities.length == 0;
          break;
      }
    },

    getMaxValueByDivision() {
      switch (this.getDivisionForTemplate) {
        case divisions.SPG:
          return this.maxCapacity.SPG;
        default:
        case divisions.FDG:
          return this.maxCapacity.FDG;
      }
    },
  },

  computed: {
		filteredConsigneesWithKey(){
			return this.filteredConsignees.map((item, index) => ({
        ...item,
        id: `${item.id}_${index}`, // Use a combination of id and index as the key
				consigneeId: item.id
      }));
		},

    ...mapState("newOrderTemplate", [
      "consignees",
      "commodities",
      "shippers",
      "suppliers",
      "accountOfs",
      "selectedConsignee",
      "selectedProducts",
      "selectedShipper",
      "selectedSupplier",
      "selectedAccountOf",
      "selectedCommodity",
      "selectedQuantity",
      "selectedAmountUnitOfMeasure",
    ]),

    ...mapGetters("newOrderTemplate", [
      "filteredConsignees",
      "getDivisionForTemplate",
      "getIsEditMode",
    ]),

    deliverySitePopoverText() {
      return 'After products are added, delivery sites will be filtered based on the "Bill To" of the first delivery site.  If you would like to add a delivery site of another bill to, please create a separate order.';
    },
  },

  watch: {
    consignees: function (val) {
      if (val.length > 0 && this.tenderForConsigneeId) {
        this.onDeliverySiteChanged(
          this.consignees.find((c) => c.id == this.tenderForConsigneeId)
        );
      }
    },

    isAddProdDisabled: function () {
      this.onChange();
    },
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.card-box-shadow {
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.27);
}

.grey-background {
  padding-top: 65px;
  background: #e4e5e6;
  height: 100%;
}

.bottom-align-text {
  position: absolute;
  bottom: 7.5px;
  left: 0;
  font-size: 20px;
}

.invalid-input {
  border: 0.5px solid #c22032;
  border-radius: 5px;
}
.wordwrap {
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
  /* max-width: 250px; */
}
</style>
