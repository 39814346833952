<template id="tmplDeliveryDetails">
  <section id="secContainerOne" class="container mb-4">
    <b-row id="rowHeadertTitle">
      <h4 id="hdrDeliveryDetailsTitle" class="text-primary">Delivery Details</h4>
    </b-row>
    <b-row id="rowTemplate">
      <b-col id="colTemplate" lg="4" md="4" sm="12" class="pd-10">
        <label id="lblTepmplateName">Template Name</label>
        <b-input v-model="templateName" style="max-width: 260px" id="txtTemplateName"
          :state="inputValidations.chkState('templateName', ValidationModel) == false ? false : null"></b-input>
        <b-form-invalid-feedback id="inputValidations" :state="inputValidations.chkState('templateName', ValidationModel)">Template Name is
          required.</b-form-invalid-feedback>
      </b-col>
      <section id="secContainterCreateEdit" class="col row" v-if="canCreateCustomerTemplates || canEditCustomerTemplates">
        <b-col lg="3" md="3" sm="12" class="pd-10">

          <b-form-checkbox id="chkbxCustomerTemplate" v-show="customerTemplateVisibility" name="chbxCustomerTemplate" v-model="isMultiUserTemplate">

          </b-form-checkbox>
          <label id="lblCustmerTemplate">Customer Template</label>
        </b-col>
        <b-col id="colMultiUser" lg="3" md="3" sm="12" class="pd-10" v-if="isMultiUserTemplate && canViewUsers">
          <label id="lblBlank"></label>
          <b-form-checkbox id="chckbxAllUsers" name="chbxCustomerTemplate" v-model="isAssignToAllUsersTemplate">
            Assign to All Users
          </b-form-checkbox>
        </b-col>
        <b-col id="colAssignUserTemplate" lg="6" md="6" sm="12" class="pd-10" v-if="isMultiUserTemplate && canViewUsers">
          <UserTemplateAssignmentMultiselect id="tmplMultiSelect" :disabled="isAssignToAllUsersTemplate"
            :customerId="loggedInUser.customerId">
          </UserTemplateAssignmentMultiselect>
        </b-col>
      </section>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "@/shared/utils/response-error-handler";

import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";
import { canViewUsers } from "@/shared/utils/permissions";
import UserTemplateAssignmentMultiselect from  "@/components/multiselects/UserTemplateAssignmentMultiSelect"

import {
  canViewCustomerTemplates,
  canModifyCustomerTemplates,
  canCreateCustomerTemplates,
  canEditCustomerTemplates,
  canDeleteCustomerTemplates,
  canCreateAdHocOrders 
  
} from "@/shared/utils/permissions";

  export default {
  name: "DeliveryDetails.vue",
  props: ["ValidationModel"],
  data() {
    return {
      inputValidations: inputValidations
    };
  },
  components: {
    vSelect,
    UserTemplateAssignmentMultiselect
  },
  methods: {
    ...mapActions("newOrderTemplate", ["setSelectedDeliverySite"]),
    async onDeliverySiteChanged(value) {
     
      try {
        this.$store.dispatch("newOrderTemplate/setSelectedConsignee", value);

        this.$store.dispatch("newOrderTemplate/clearSelectedProducts");

        var commodites = await this.$store.dispatch(
          "newOrderTemplate/getCommodities",
          {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id
          }
        );

        var shippers = await this.$store.dispatch(
          "newOrderTemplate/getShippers",
          {
            jwtToken: this.$store.state.user.jwtToken,
            billTo: this.$store.state.newOrderTemplate.selectedConsignee.billTo,
            consignee: this.selectedConsignee.id
          }
        );

      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    }
  },
  computed: {
    ...mapState("newOrderTemplate", [
        "consignees",
        "selectedConsignee"
      ]),
    ...mapGetters("user", ["loggedInUser"]),
    isMultiUserTemplate: {
      get() {
        // if (canCreateCustomerTemplates) {
        //   this.$store.dispatch("newOrderTemplate/setIsMultiUserTemplate", true);
        // }
        return this.$store.state.newOrderTemplate.isMultiUser;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setIsMultiUserTemplate", value);
      }
    },
    isAssignToAllUsersTemplate: {
      get() {
        return this.$store.state.newOrderTemplate.isAssignToAllUsers;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setIsAssignToAllUsers", value);
      }
    },
    templateName: {
      get() {
        return this.$store.state.newOrderTemplate.templateName;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setTemplateName", value);
      }
    },
    canViewUsers() {
      return canViewUsers(this.$store)
    },
    canViewCustomerTemplates(){
      return canViewCustomerTemplates(this.$store)
    },
    canCreateCustomerTemplates(){
      return canCreateCustomerTemplates(this.$store)
    },
    canEditCustomerTemplates(){
      return canEditCustomerTemplates(this.$store)
    },
    canDeleteCustomerTemplates(){
      return canDeleteCustomerTemplates(this.$store)
    },
    canModifyCustomerTemplates(){
      return canModifyCustomerTemplates(this.$store)
    },
    canCreateAdHocOrders() {
      return canCreateAdHocOrders(this.$store);
    },
    customerTemplateVisibility() {
      if (this.canCreateAdHocOrders && this.canCreateCustomerTemplates)
        return true;
      else if (!this.canCreateAdHocOrders && this.canCreateCustomerTemplates)
        return false;
    }
  },
  mounted() {
    if (!this.canCreateAdHocOrders && this.canCreateCustomerTemplates) {
      this.isMultiUserTemplate = true;
    }
  }
};
</script>

<style scoped>
</style>


